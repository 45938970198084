<template>
  <div class="hello">
    
    <h3>Component ичидан</h3>
    <slot ></slot>
    
  </div>
</template>

<script setup>

 
</script>

