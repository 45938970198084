<template>
  <div class="container">
    <div class="bg-secondary-subtle rounded-2 p-2">
      <div class="row g-3">
        <div class="col-lg-6">
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >Citizenship</label
            >

            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput"
              placeholder="Enter address"
            />
          </form>
        </div>
        <div class="col-lg-6">
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >Country</label
            >

            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput"
              placeholder="Month"
            />
          </form>
        </div>
      </div>
      <div class="row g-3 mt-1">
        <div class="col-lg-6">
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >Day Phone</label
            >

            <input
              type="tel"
              class="form-control"
              id="exampleFormControlInput"
              placeholder="(xx)xxx-xxxx"
            />
          </form>
        </div>
        <div class="col-lg-6">
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >Evening Phone</label
            >

            <input
              type="tel"
              class="form-control"
              id="exampleFormControlInput"
              placeholder="(xx)xxx-xxxx"
            />
          </form>
        </div>
      </div>
      <div class="row g-3 mt-1">
        <div class="col-lg-6">
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >Address Line 1</label
            >

            <input
              type="tel"
              class="form-control"
              id="exampleFormControlInput"
              placeholder="(xx)xxx-xxxx"
            />
          </form>
        </div>
        <div class="col-lg-6">
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >Address Line 2</label
            >

            <input
              type="tel"
              class="form-control"
              id="exampleFormControlInput"
              placeholder="(xx)xxx-xxxx"
            />
          </form>
        </div>
      </div>
      <div class="row g-3 mt-1">
        <div class="col-lg-6">
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >City</label
            >

            <input
              type="tel"
              class="form-control"
              id="exampleFormControlInput"
              placeholder="(xx)xxx-xxxx"
            />
          </form>
        </div>
        <div class="col-lg-3">
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >State</label
            >

            <input
              type="number"
              class="form-control"
              id="exampleFormControlInput"
            />
          </form>
        </div>
        <div class="col-lg-3">
          <form>
            <label for="exampleFormControlInput1" class="form-label"
              >Zip Code</label
            >

            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped></style>
