<template>
  <div class="container">
    <div class="bg-secondary-subtle rounded-2 p-2">
      <div class="row g-3">
        <div class="col-lg-3">
          <form class="mt-3">
            <input
              type="radio"
              name=""
              id=""
              class="exampleFormControlInput-control"
            />
            <label for="exampleFormControlInput1" class="form-label ms-1"
              >Tax ID</label
            >
          </form>
        </div>
        <div class="col-lg-3">
          <form class="mt-3">
            <input
              type="radio"
              name=""
              id=""
              class="exampleFormControlInput-control"
            />
            <label for="exampleFormControlInput1" class="form-label ms-1"
              >Social Security Number</label
            >
          </form>
        </div>
        <div class="col-lg-6">
          <form class="mt-2">
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput"
              placeholder="9 Digit Number"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style></style>
