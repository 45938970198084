<template>
  <div class="container">
    <div class="bg-secondary-subtle rounded-2 p-2">
      <div class="row g-3">
        <div class="col-lg-6 mt-4">
          <div class="mb-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label class="form-check-label" for="exampleCheck1"
              >Subject to backup withholding</label
            >
          </div>
        </div>
        <div class="col-lg-6 mt-4">
          <div class="mb-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label class="form-check-label" for="exampleCheck1"
              >I want to reinvest my dividends</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped></style>
